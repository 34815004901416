@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
 body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

html {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  transition: all 0.3s ease;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root{
  --white: #fff;
  --black: #24292d;
  --nav-main: #4070f4;
  --switchers-main: #0b3cc1;
  --light-bg: #f0f8ff;
  --home-content-margin-top: 70px;
}

button{
  width: 100px;
  border: none;
  border-radius: 4px;
  align-self: flex-end;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.25rem 1rem;
  margin-top: 1rem;
  color: white;
  outline: none;
  cursor: pointer;
  background-color: #4070f4;
  background-color: var(--nav-main);
  opacity: 0.8
}

button:hover{
  opacity: 1;
}

button.full-width{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  width: 100%;
  font-size: 1.125rem;
}

button:disabled{
  opacity: 1;
  background-color: silver;
  cursor: default;
  transition: none;
}


/* Login CSS */

.login {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #f0f8ff;
  background: var(--light-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login .white-box{
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.025);
  padding: 2rem;
  width: 360px;
  background: white;
  border-radius: 2px;
}

.login .white-box .logo-area{
  display: block;
  padding-bottom: 2rem;
  text-align: center;
  font-weight: bold;
  letter-spacing: 2px;
}

.login .white-box .error-msg{
  color: red;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 1rem;
}

.login .white-box h3{
  margin: 0;
  color: #343a40;
  margin-bottom: 1rem;
}

.login .white-box input{
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid rgba(0,0,0, 0.2);
  padding-bottom: 0.5rem;
  outline: none;
  width: 100%;
  margin-top: 1rem;
}
.login .white-box input:focus{
  border-bottom: 1px solid rgba(0,0,0,1);
}

.login .white-box .footer{
  margin-top: 2rem;
  text-align: right;
}

.login .white-box .footer a{
  color: #868e96;
  text-decoration: underline;
}
.login .white-box .footer a:hover{
  color: #212529;
}

.login .white-box button{
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.25rem 1rem;
  margin-top: 1rem;
  color: white;
  outline: none;
  cursor: pointer;
  background-color: #4070f4;
  background-color: var(--nav-main);
  opacity: 0.8
}

.login .white-box button:hover{
  opacity: 1;
}

.login .white-box button.full-width{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  width: 100%;
  font-size: 1.125rem;
}


.login .white-box button:disabled{
  opacity: 1;
  background-color: silver;
  cursor: default;
  transition: none;
}
/* //////////////////////////////////////////////////////////// */

/* Filter Box CSS */

.filter-boxes{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 20px;
  flex-wrap: wrap;
}
.filter-boxes .box{
  width: 100%;
  display: flex;
  padding: 15px 14px;
  background: #fff;
  background: var(--white);
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  flex-direction: column;
  height: 335px;
  z-index: 1;
  overflow-x: auto;
}

.select-box .box::-webkit-scrollbar{
  width: 8px;
  border-left: 1px solid;
  background: #e9ecef;
  border-radius: 0 0 8px 0;
}
.select-box .options-container::-webkit-scrollbar-thumb{
  background: #fff;
  background: var(--white);
  border-left: 1px solid;
  background: #868e96;
  border-radius: 0 0 8px 0;
}
.home-content .filter-boxes .box .title{
  display: block;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}
.home-content .filter-boxes .box .filter-details{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-boxes .box.sticky{
  position: fixed;
  top: 70px;
  left: 0;
  height: 335px;
  width: 100%;
  background: #fff;
  background: var(--white);
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  border-radius: 0px;
  z-index: 1;
  transition: none;
}

.filter-details select{
  height: 40px;
  width: auto;
  min-width: 170px;
  position: relative;
  margin-right: 20px;
  border: none;
}

.filter-details table{
  width: 100%;
  min-width: 1360;
  justify-content: space-between;
}

/* //////////////////////////////////////////////////////////// */

/* Tags Input CSS */
.tag-box-content .tag-box{
  height: 40px;
  width: auto;
  min-width: 170px;
  position: relative;
  margin: 0 10px 10px 0;
  border-bottom: 2px solid silver;
}

.tag-box-content .tag-label{
  position: absolute;
  bottom: 10px;
  left: 0;
  color: grey;
  pointer-events: none;
}
.tag-box-content .tag-label.active{
  color: #4070f4;
  color: var(--nav-main);
  transform: translateY(-25px);
  font-size: 12px;
}

.tag-box-content .tag-label.have{
  transform: translateY(-25px);
  font-size: 12px;
}

.tag-box-content ul{
  display: flex;
  white-space: nowrap;
}
.tag-box-content :where(li, i){
  display: flex;
  align-items: center;
}

.tag-box-content ul li{
  color: grey;
  list-style: none;
  margin: 3px;
  padding: 6px 5px 5px 5px;
  font-size: 12px;
  font-weight: 500;
  transition: none;
  cursor: default;
}

.tag-box-content ul li:hover{
  color: #fff;
  color: var(--white);
  border-radius: 5px;
  background: #4070f4;
  background: var(--nav-main);
  opacity: 0.8;
  transition: none;
}
.tag-box-content ul li i{
  cursor: pointer;
  margin-left: 3px;
  transition: none;
}
.tag-box-content ul input{
  flex: 1 1;
  outline: none;
  border: none;
  background-color: #fff;
  background-color: var(--white);
  padding: 5px 5px 5px 0;
  font-size: 17px;
  transition: none;
}
.tag-box-content ul input:-ms-input-placeholder{
  color: grey;
}
.tag-box-content ul input::placeholder{
  color: grey;
}
.tag-box-content .activeline{
  position: absolute;
  bottom: -1.5px;
  height: 2px;
  width: 100%;
  background: #4070f4;
  background: var(--nav-main);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}
.tag-box-content .activeline.active{
  transform: scaleX(1);
}
.tag-box-content .underline.active{
  background: #4070f4;
  background: var(--nav-main);
  transition: transform 0.3s ease;
}


/* //////////////////////////////////////////////////////////// */

/* Text Field CSS */
.input-data{
  height: 40px;
  width: auto;
  min-width: 170px;
  position: relative;
  margin: 0 10px 10px 0;
}

.input-data input{
  height: 100%;
  width: 100%;
  border: none;
  font-size: 17px;
  background-color: #fff;
  background-color: var(--white);
  border-bottom: 2px solid silver;
  transition: none;
}
.input-data input:focus{
  outline: none;
}

.input-data label{
  position: absolute;
  bottom: 10px;
  left: 0;
  color: grey;
  pointer-events: none;
}

.input-data input:focus ~ label{
  color: #4070f4;
  color: var(--nav-main);
  transform: translateY(-20px);
  font-size: 12px;
}
.input-data input:valid ~ label{
  transform: translateY(-20px);
  font-size: 12px;
}

.input-data .underline{
  position: absolute;
  bottom: 0px;
  height: 2px;
  width: 100%;
}
.input-data .underline:before{
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #4070f4;
  background: var(--nav-main);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}
.input-data input:focus ~ .underline:before{
  transform: scaleX(1);
}

/* //////////////////////////////////////////////////////////// */

/* Filter Select CSS */
 .select-box{
  display: flex;
  width: auto;
  min-width: 80px;
  height: 40px;
  position: relative;
  margin: 0 10px 10px 0;
  flex-direction: column;
  cursor: pointer;
}

 .select-box .options-container{
  position: absolute;
  top: 40px;
  background: #fff;
  background: var(--white);
  color: #24292d;
  color: var(--black);
  max-height: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.4s;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  border: 1px solid #24292d;
  border: 1px solid var(--black);
  order: 1;
  z-index: 100;
}
 .select-box .selected label{
  position:absolute;
  bottom: 10px;
  left: 0;
  color: grey;
  white-space: nowrap; 
  width: calc(100% - 25px); 
  overflow: hidden;
  text-overflow: ellipsis; 
  display: inline-block;
  
}
 .select-box .underline{
  position: absolute;
  bottom: 0px;
  height: 2px;
  width: 100%;
  background: silver;
}
 .select-box .activeline{
  position: absolute;
  bottom: 0px;
  height: 2px;
  width: 100%;
  background: #4070f4;
  background: var(--nav-main);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}
 .select-box .activeline.active{
  transform: scaleX(1);
}
 .select-box .underline.active{
  background: #4070f4;
  background: var(--nav-main);
  transition: transform 0.3s ease;
}
 .select-box .selected i{
  content: "";
  text-align: center;
  position: absolute;
  font-size: 20px;
  right: 10px;
  top: 10px;
  color: silver;
}
 .select-box .selected label.value{
  color: black;
}
 .select-box .selected.active i{
  transform: rotate(180deg);
  color: #4070f4;
  color: var(--nav-main);
}
 .select-box .options-container.active{
  max-height: 150px;
  opacity: 1;
  overflow-y: scroll;
}
 .select-box .options-container::-webkit-scrollbar{
  width: 8px;
  border-left: 1px solid;
  background: #e9ecef;
  border-radius: 0 0 8px 0;
}
 .select-box .options-container::-webkit-scrollbar-thumb{
  background: #fff;
  background: var(--white);
  border-left: 1px solid;
  background: #868e96;
  border-radius: 0 0 8px 0;
}
 .select-box .option{
  min-height: 45px;
  height: 100%;
  padding: 12px 24px;
  cursor: pointer;
}
 .select-box .option:hover{
  background: #4070f4;
  background: var(--nav-main);
  color: #fff;
  color: var(--white);
  transition: none;
}
 .select-box label{
  cursor: pointer;
}

 .select-box .option .radio{
  display: none;
}
/* //////////////////////////////////////////////////////////// */

/* checkbox-box CSS */
.filter-boxes .checkbox-box{
  display: block;
  min-width: 960px;
  height: 130px;
  border: 2px solid silver;
  border-radius: 12px;
  color: gray;
}
.filter-boxes .checkbox-box .name{
  margin: 10px; 
}
.filter-boxes .checkbox-box .checkbox-list{
  margin: 10px;
  display: flex;
}
.filter-boxes .checkbox-box .checkbox-list .row{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.filter-boxes .checkbox-box .checkbox-list .row .check-box{
  margin: 0 10px 10px 0;
  justify-content: center;
  width: 170px;
  transition: none;
  cursor: pointer;
}
.filter-boxes .checkbox-box .checkbox-list .row .check-box label{
  transition: none;
  cursor: pointer;
}
.filter-boxes .checkbox-box .checkbox-list .row .check-box.checked{
  color: #24292d;
  color: var(--black);
  font-weight: 500;
}
.filter-boxes .checkbox-box .checkbox-list .row .check-box.checked label{
  transition: none;
}
.filter-boxes .checkbox-box .checkbox-list .row .check-box input{
  margin: 5px;
}
.filter-boxes .checkbox-box .checkbox-list .row .check-box label{
  height: 100%;
  font-size: 12px;
}

/* //////////////////////////////////////////////////////////// */

/* Loading Spinner CSS */

.loading-wrapper {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  z-index: 99999;
}
.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transition: none;
}

@keyframes animate{
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

.dots span{
  position: absolute;
  height: 10px;
  width: 10px;
  background: #4070f4;
  background: var(--nav-main);
  border-radius: 50%;
  transform: rotate(calc(var(--i) * (360deg / 15))) translateY(35px);
  animation: animate 1.5s linear infinite;
  animation-delay: calc(var(--i) * 0.1s);
  opacity: 0.3;
}

/* //////////////////////////////////////////////////////////// */

/* Popup CSS */
.popup {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  z-index: 99999;
  visibility: hidden;
  transition: none;
}
.popup.visible{
  visibility: visible;
}

.popup .popup-pannel{
  position: fixed;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 500px;
  border-radius: 5px;
  background: white;
  margin-top: -250px;
  margin-left: -250px;
  transition: none;
}

.popup .popup-pannel .popup-inner{
  display: flex;
  margin: 15px;
  align-items: center;
  justify-content: space-between;
  transition: none;
}
.popup-inner i.close{
  margin-left: auto;
  color: silver;
  transition: inherit;
  cursor: pointer;
}
.popup-inner i.close:hover{
  color: #24292d;
  color: var(--black);
}


/* //////////////////////////////////////////////////////////// */

/* Top Navigator CSS */

nav{
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  width: 100%;
  background: #4070f4;
  background: var(--nav-main);
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  z-index: 2;
}

nav .navbar{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  padding: 0 30px;
}

.navbar .nav-links{
  display: flex;
  height: 100%;
  line-height: 70px;
}

.navbar a{
  font-size: 30px;
  font-weight: 500;
  color: #fff;
  color: var(--white);
  text-decoration: none;
}

.navbar .nav-links li{
  list-style: none;
  margin: 0 8px;
}

.navbar .nav-links li a{
  white-space: nowrap;
  height: 100%;
  font-size: 18px;
  font-weight: 400;
  opacity: 0.9;
}
.navbar .nav-links li a:hover{
  opacity: 1;
}
.navbar .nav-links .sub-menu{
  position: absolute;
  top: 70px;
  background: #4070f4;
  background: var(--nav-main);
  line-height: 40px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  display: none;
}
.navbar .nav-links .sub-menu li{
  padding: 0 22px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  color: #fff;
  color: var(--white);
  font-size: 15px;
  font: 500;
  cursor: pointer;
}

.navbar .nav-links li:hover .eventTypeView-sub-menu{
  display: block;
}

.nav-links .fa-caret-down.open{
  transform: rotate(180deg)
}

.navbar .appearance{
  display: flex;
  align-items: center;
}

.appearance .color-icon{
  position: relative;
}

.appearance .user,
.appearance .light-dark,
.appearance .color-icon .icons{
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  color: var(--white);
  font-size: 20px;
  margin: 0 10px;
  border-radius: 6px;
  background: #0b3cc1;
  background: var(--switchers-main);
  opacity: 0.8;
  cursor: pointer;
}
.appearance .color-icon .icons{
  width: 70px;
}

.appearance .user:hover,
.appearance .light-dark:hover,
.appearance .color-icon .icons:hover{
  opacity: 1;
}

.appearance .user-icon .user-box{
  position: absolute;
  top: 60px;
  right: 0;
  background: #4070f4;
  background: var(--nav-main);
  border-radius: 0 0 5px 5px;
  display: none;
}

.user-icon.open .user-box{
  display: block;
}
.user-icon .user-box li.user-name{
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}
.user-icon .user-box li{
  list-style: none;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  color: var(--white);
  padding: 10px 22px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
}

.user-icon .user-box li a{
  font-size: 16px;
  font-weight: 500;
}

.appearance .color-icon .color-box{
  position: absolute;
  bottom: -133px;
  right: 0;
  min-height: 100px;
  background: #fff;
  background: var(--white);
  border-radius: 6px;
  padding: 16px 20px 20px 20px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  opacity: 0;
  pointer-events: none;
}
.color-icon.open .color-box{
  opacity: 1;
  pointer-events: auto;
}
.color-icon.open .arrow{
  transform: rotate(-180deg);
}
.color-icon .color-box::before{
  content: '';
  position: absolute;
  right: 20px;
  top: -10px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #fff;
  background: var(--white);
}
.color-icon .color-box h3{
  display: inline-block;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
}
.color-box .color-switchers{
  display: flex;
}
.color-box .color-switchers .btn{
  display: inline-block;
  height: 40px;
  width: 40px;
  outline: none;
  border: none;
  border-radius: 50%;
  background: #4070f4;
  background: var(--nav-main);
  margin: 0 5px;
}

.color-switchers .btn.blue{
  background: #4070f4;
}

.color-switchers .btn.blue.active{
  box-shadow: 0 0 0 2px #fff,
              0 0 0 4px #4070f4;
}

.color-switchers .btn.orange{
  background: #f79f1f;
}

.color-switchers .btn.orange.active{
  box-shadow: 0 0 0 2px #fff,
              0 0 0 4px #f79f1f;
}

.color-switchers .btn.purple{
  background: #8e44ad;  
}

.color-switchers .btn.purple.active{
  box-shadow: 0 0 0 2px #fff,
              0 0 0 4px #8e44ad;
}

.color-switchers .btn.green{
  background: #3a9943;
}
.color-switchers .btn.green.active{
  box-shadow: 0 0 0 2px #fff,
              0 0 0 4px #3a9943;
}

/* //////////////////////////////////////////////////////////// */

/* Home Content CSS */

.home-content{
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  margin-top: var(--home-content-margin-top);
  padding-top: 25px;
  width: 100%;
  background: #f0f8ff;
  background: var(--light-bg);
  transition: none;
}
.home-content.sticky{
  display: flex;
  flex-direction: column;
  margin-top: 320px;
  padding-top: 25px;
  height: 100%;
  width: 100%;
  background: #f0f8ff;
  background: var(--light-bg);
}
.home-content .overview-boxes{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 20px;
  flex-wrap: wrap;
}
.home-content .overview-boxes .box{
  width: calc(100% / 4 - 15px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 14px;
  background: #fff;
  background: var(--white);
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
}
.home-content .overview-boxes .box_topic{
  font-size: 20px;
  font-weight: 500;
}
.home-content .overview-boxes .number{
  font-size: 35px;
  font-weight: 500;
  display: inline-block;
}
.overview-boxes .indicator{
  display: flex;
  align-items: center;
}
.overview-boxes .indicator i{
  color: #8fdacb;
  text-align: center;
  margin: 0 5px;
  font-size: 20px;
}
.overview-boxes .indicator i.down{
  color: #e87d88;
}
.overview-boxes .indicator .text{
  font-size: 12px;
}
.overview-boxes .box .cart{
  font-size: 24px;
  height: 50px;
  width: 50px;
  background: #cce5ff;
  color: #66b0ff;
  line-height: 50px;
  text-align: center;
  border-radius: 12px;
}
.overview-boxes .box .cart.two{
  background: #C0f2d8;
  color: #28d474;
}
.overview-boxes .box .cart.three{
  background: #ffe8b3;
  color: #ffc233;
}
.overview-boxes .box .cart.four{
  background: #f7d4d7;
  color: #e05260;
}
.home-content .view-boxes{
  display: flex;
  justify-content: space-between;
  min-height: calc(100vh - 70px - 20px - 335px - 25px);
  min-height: calc(100vh - var(--home-content-margin-top) - 20px - 335px - 25px);
  padding: 20px;
}
.home-content .view-boxes .recent-sale .top-space{
  display: flex;
  justify-content: space-between; 
}
.home-content .view-boxes .recent-sale .top-space .select-index{
  display: flex;
}

.home-content .view-boxes .recent-sale .top-space .select-index .index{
  font-size: 10px;
  font-weight: 500;
  margin-top: 10px;
  margin-right: 10px;
  align-items: center;
}
.home-content .view-boxes .recent-sale{
  width: 100%;
  background: #fff;
  background: var(--white);
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  padding: 20px;
}
.home-content .view-boxes .recent-sale::-webkit-scrollbar{
  width: 10px;
  background: #fff;
  background: var(--white);
  border-radius: 12px;
}
.home-content .view-boxes .recent-sale::-webkit-scrollbar-thumb{
  background: #4070f4;
  background: var(--nav-main);
  border-radius: 12px;
}
.home-content .view-boxes .view-details{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}
.view-details table{
  flex-shrink: 0;
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
}
.view-details table thead{
  border-bottom: 2px solid black;
}
.view-details table th{
  padding: 10px;
  font-size: 20px;
  font-weight: 500;
  pointer-events: none;
}
.view-details table td{
  margin: 8px 0;
  text-align: center;
  padding: 3px;
  text-overflow: ellipsis;
  overflow:hidden;
  white-space:nowrap;
  max-width: 170px;
}

.view-details table td label.cursor{
  cursor: pointer;
}
.view-details table td label.cursor:hover{
  font-weight: 600;
}
.view-details table tr{
  border-bottom: 1px solid rgba(0,0,0,0.2);
}

.view-details table .Paused{
  background: rgba(255,0,0,0.05);
}
.view-details table .Started{
  background: rgba(0,255,0,0.05);
  
}
.view-details table .Submitted{
  background: rgba(0,0,255,0.05);
}
.view-details table .Paused:hover{
  background: rgba(255,0,0,0.2);
}
.view-details table .Started:hover{
  background: rgba(0,255,0,0.2);
  
}
.view-details table .Submitted:hover{
  background: rgba(0,0,255,0.2);
}

.view-boxes .error-details{
  text-align: left;
  color: red;
  font-weight: 600;
  transition: none;
}

.view-boxes .view-details{
  transition: none;
}

.view-boxes table .event-time{
  min-width: 220px;
}
.home-content .view-boxes .view-details .time li{
  width: 70px;
}
.home-content .view-boxes .view-details .details{
  margin-right: 10px;
}
.home-content .view-boxes .box{
  margin-bottom: 20px;
  overflow-x: auto;
}
.home-content .view-boxes .box .title{
  display: block;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}
.home-content .view-boxes .box .topic{
  font-size: 20px;
  font-weight: 500;
}

.home-content .view-boxes .box .topic .time{
  width: 55px;
}
.home-content .view-boxes .box li{
  list-style: none;
  margin: 8px 0;
}
.view-boxes .box li a{
  color: #333;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
}

.home-content .view-boxes .top-sales{
  width: 20%;
  margin-left: 20px;
  background: #fff;
  background: var(--white);
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  padding: 20px;
}

.home-content .admin-boxes{
  display: flex;
  justify-content: space-between;
  min-height: calc(100vh - 70px - 20px - 25px);
  min-height: calc(100vh - var(--home-content-margin-top) - 20px - 25px);
  padding: 20px;
}

.home-content .admin-boxes .main{
  width: 100%;
  background: #fff;
  background: var(--white);
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  padding: 20px;
}

.home-content .admin-boxes .button-area{
  display: flex;
  flex-direction: row-reverse;
}

.home-content .admin-boxes .button-area button{
  width: 10%;
  margin: 5px;
}
.home-content .admin-boxes .box{
  margin-bottom: 20px;
  overflow-x: auto;
}
.home-content .admin-boxes .box .title{
  display: block;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}
.home-content .admin-boxes .box .topic{
  font-size: 20px;
  font-weight: 500;
}

.home-content .admin-boxes .admin-details{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.admin-details table{
  flex-shrink: 0;
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
}
.admin-details table thead{
  border-bottom: 2px solid black;
}
.admin-details table th{
  padding: 10px;
  font-size: 20px;
  font-weight: 500;
  pointer-events: none;
}
.admin-details table td{
  margin: 8px 0;
  text-align: center;
  padding: 3px;
}

.admin-details table td label.cursor{
  cursor: pointer;
}
.admin-details table td label.cursor:hover{
  font-weight: 600;
}
.admin-details table tr{
  cursor: pointer;
  border-bottom: 1px solid rgba(0,0,0,0.2);
}
.admin-details table tr:hover{
  background: #f0f8ff;
}

.admin-details table .Paused{
  background: rgba(255,0,0,0.05);
}
.admin-details table .Started{
  background: rgba(0,255,0,0.05);
  
}
.admin-details table .Submitted{
  background: rgba(0,0,255,0.05);
}
.admin-details table .Paused:hover{
  background: rgba(255,0,0,0.2);
}
.admin-details table .Started:hover{
  background: rgba(0,255,0,0.2);
  
}
.admin-details table .Submitted:hover{
  background: rgba(0,0,255,0.2);
}

.admin-boxes table .event-time{
  width: 290px;
}
.home-content .admin-boxes .admin-details .time li{
  width: 290px;
}
.home-content .admin-boxes .admin-details .details{
  margin-right: 10px;
}



.home-content h2{
  font-size: 40px;
  /* color: var(--black); */
}

.home-content h3{
  font-size: 30px;
  /* color: var(--black);   */
}

.home-content h3 span{
  color: #4070f4;
  color: var(--nav-main);
}

.home-content p{
  font-size: 14px;
  width: 40%;
  margin-bottom: 30px;
}

.home-content .footer{
  height: 20px;
  text-align: center;
  font-size: 12px;
  background: #fff;
  background: var(--white);
  color: #24292d;
  color: var(--black);
}

.navbar .fa-bars,
.nav-links .sidebar-logo .logo_name,
.nav-links .sidebar-logo .fa-times
 {
  display: none;
}

@media (max-width: 1440px) {
  .view-details table{
    table-layout: auto;
    width: 1360px;
  }
  .admin-details table{
    table-layout: auto;
    width: 1360px;
  }
}
@media (max-width: 995px) {
  .home-content .overview-boxes .box{
    width: calc(100% / 2 - 15px);
    margin-bottom: 15px;
  }
}

@media (max-width: 527px) {
  .home-content .overview-boxes .box{
    width: calc(100% - 15px);
    margin-bottom: 15px;
  }
  nav .navbar .logo a{
    font-size: 14px;
  }
} 

@media (max-width: 794px) {
  nav .navbar{
    max-width: 100%;
    padding: 0 25px;
  }
  nav .navbar .logo a{
    font-size: 26px;
  }
  nav .navbar .nav-links li{
    padding: 0 10px;
  }
  nav .navbar .nav-links li a{
    font-size: 16px;
  }
}@media (max-width: 787px) {
  nav .navbar .nav-links{
    display: block;
    position: fixed;
    background: #4070f4;
    background: var(--nav-main);
    top: 0;
    left: -100%;
    max-width: 270px;
    width: 100%;

  }

  nav .navbar .nav-links.open{
    left: 0;
  }
  nav .navbar .nav-links .sidebar-logo{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .navbar .fa-bars{
    display: block;
    color: #fff;
    color: var(--white);
    font-size: 25px;
    cursor: pointer;
  }

  .nav-links .sidebar-logo .logo_name{
    display: block;
    color: #fff;
    color: var(--white);
    font-size: 25px;
    font-weight: 500;
    padding-left: 15px;
  }
  .nav-links .sidebar-logo .fa-times{
    display: block;
    color: #fff;
    color: var(--white);
    font-size: 20px;
    padding-right: 15px;
    cursor: pointer;
  }
  .navbar .nav-links .sub-menu{
    display: none;
    position:relative;
    top: 0;
    left: 0;
    box-shadow: none;
  }
  .navbar .nav-links .sub-menu li{
    border-bottom: none;
  }
  .navbar .nav-links li:hover .eventTypeView-sub-menu{
    display: block;
  }
  .nav-links .fa-caret-down{
    transform: rotate(-90deg);
  }
  .nav-links .fa-caret-down.open{
    transform: rotate(0deg)
  }
  
}
